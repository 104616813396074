import { create } from 'zustand';
import { ToastAnchorType } from '../../components/atoms/Toast/types';

type Variant = 'error' | 'success';

type State = {
  showToast: boolean;
  anchor: ToastAnchorType;
  variant: Variant;
  message: string;
  bottom: string;
  setShowToast: () => void;
  setToastData: ({ variant, message }: { variant: Variant; message: string }) => void;
  setToastAnchor: ({ anchor }: { anchor: ToastAnchorType }) => void;
  setBottom: ({ bottom }: { bottom: string }) => void;
};

export const useToast = create<State>(set => ({
  showToast: false,
  variant: 'error',
  anchor: { vertical: 'top', horizontal: 'right' },
  message: '',
  bottom: '8px',
  setShowToast: () => set(state => ({ showToast: !state.showToast })),
  setToastData: ({ variant, message }) => set(() => ({ variant, message })),
  setToastAnchor: ({ anchor }) => set(() => ({ anchor })),
  setBottom: ({ bottom }) => set(() => ({ bottom })),
}));
