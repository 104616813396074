import { unlockBikeSuccess } from '../../../constants/customSwipeableMessages.const';
import { BPV_PAGE_TITLE_PREFIX } from '../../../constants/events.const';
import { AppRoute, LINKS, ROUTES } from '../../../constants/routes.const';
import {
  IconClock,
  IconError2,
  IconInvestigation,
  IconLoadingLocation,
  IconLocation,
  IconWallet,
} from '../../../modules/temBpv/components/atoms/Icons';

export type SwipeableKeys = keyof typeof SwipeableTypes;

export enum SwipeableTypes {
  INVALID_DATE = 'INVALID_DATE',
  SUCCESS_SCHEDULING = 'SUCCESS_SCHEDULING',
  CANCEL_PLAN_ERROR = 'CANCEL_PLAN_ERROR',
  ACTIVATE_PLAN_ERROR = 'ACTIVATE_PLAN_ERROR',
  THANKS_FOR_THE_FEEDBACK = 'THANKS_FOR_THE_FEEDBACK',
  EDIT_PERSONAL_DATA = 'EDIT_PERSONAL_DATA',
  SEND_PHOTO_FRONT = 'SEND_PHOTO_FRONT',
  SEND_PHOTO_BACK = 'SEND_PHOTO_BACK',
  PROOF_OF_RESIDENCE = 'PROOF_OF_RESIDENCE',
  ADD_CARD_ERROR = 'ADD_CARD_ERROR',
  CHECK_IN = 'CHECK_IN',
  CHECK_IN_SUCCESS = 'CHECK_IN_SUCCESS',
  UNLOCK_BIKE_SUCESS = 'UNLOCK_BIKE_SUCESS',
  ADD_CARD_FAIL = 'ADD_CARD_FAIL',
}

export enum PhotoTypes {
  FRONT = 'FRONT',
  BACK = 'BACK',
  PROOF_OF_RESIDENCE = 'PROOF_OF_RESIDENCE',
}

interface IButtonData {
  buttonText?: string;
  buttonSecondaryText?: string;
  isEnablePhoto?: boolean;
}
interface IRouteData {
  defaultRoute?: string;
  secondaryRoute?: string;
  backRoute?: AppRoute;
}

interface IContentData {
  title: string;
  subtitle?: string;
  defaultMessage: string;
  image: () => JSX.Element;
  anchor: 'bottom' | 'left' | 'right' | 'top';
  photoType?: keyof typeof PhotoTypes;
}
interface EventData {
  pageTitle?: string;
  events?: string[];
  buttonEventName?: string;
  buttonSecondaryEventName?: string;
}

interface IMessageData extends IButtonData, IRouteData, IContentData, EventData {}

type SwipeableDataCollection = { [V in SwipeableTypes]: IMessageData };

export const CONTENT_DATA: SwipeableDataCollection = {
  INVALID_DATE: {
    title: 'Data indisponível',
    defaultMessage:
      'Escolha um horário no período da manhã ou da tarde de segunda a sexta-feira para agendar.',
    defaultRoute: ROUTES.BPV_HOME,
    image: IconClock,
    buttonText: 'Entendi',
    anchor: 'bottom',
  },
  SUCCESS_SCHEDULING: {
    title: 'Informações importantes',
    defaultMessage:
      'Teve um imprevisto? Você pode reagendar até 48h antes para não perder a vez. <br/> <br/>No dia agendado é importante que você apresente um documento pessoal com foto. <br/><br/>Evite problemas na cobrança: leve um cartão de crédito físico válido e com limite.',
    defaultRoute: ROUTES.BPV_HOME,
    image: IconClock,
    buttonText: 'Entendi',
    anchor: 'bottom',
  },
  CANCEL_PLAN_ERROR: {
    title: 'Não foi possível cancelar seu plano',
    defaultMessage:
      'Você tem um plano ativo e não conseguimos cancelar. Converse com o nosso atendimento antes de tentar novamente!',
    defaultRoute: ROUTES.BPV_HOME,
    image: IconError2,
    buttonText: 'Entendi',
    anchor: 'bottom',
  },
  ACTIVATE_PLAN_ERROR: {
    title: 'Não foi possível ativar seu plano',
    defaultMessage:
      'Não conseguimos ativar o plano. Converse com o nosso atendimento antes de tentar novamente!',
    defaultRoute: ROUTES.BPV_HOME,
    image: IconError2,
    buttonText: 'Entendi',
    anchor: 'bottom',
  },
  THANKS_FOR_THE_FEEDBACK: {
    title: 'Obrigado pelo feedback!',
    defaultMessage:
      'Procure uma pessoa do atendimento para que ele possa te auxiliar na resolução do seu problema.',
    defaultRoute: ROUTES.BPV_HOME,
    image: IconError2,
    buttonText: 'Prosseguir',
    anchor: 'bottom',
  },
  EDIT_PERSONAL_DATA: {
    title: 'Opa, desculpe!',
    defaultMessage: 'Não conseguimos editar seus dados, entre em contato com a gente.',
    defaultRoute: LINKS.SUPPORT_BPV,
    image: IconInvestigation,
    buttonText: 'Falar com atendimento',
    anchor: 'bottom',
    buttonSecondaryText: 'Voltar',
    events: ['personal_info_click_fail'],
    buttonEventName: 'personal_info_click_fail_primary_btn',
    buttonSecondaryEventName: 'personal_info_click_fail_secondary_btn',
    pageTitle: `${BPV_PAGE_TITLE_PREFIX}/signup/personal-info-confirmation`,
  },
  SEND_PHOTO_FRONT: {
    title: 'Envio de foto',
    defaultMessage:
      "<p style='margin-bottom: 8px'>Você pode enviar apenas um arquivo JPG, JPEG, PNG ou PDF O tamanho máximo do arquivo é de 10 Mb</p><p style='margin-bottom: 8px'>O tamanho máximo do arquivo é de 10 Mb</p>",
    image: IconInvestigation,
    buttonText: 'Escolher Foto',
    anchor: 'bottom',
    buttonSecondaryText: 'Voltar',
    photoType: 'FRONT',
    isEnablePhoto: true,
    pageTitle: `${BPV_PAGE_TITLE_PREFIX}/signup/id-validation-front`,
    buttonEventName: 'id_validation_front_choose_file_click',
  },
  SEND_PHOTO_BACK: {
    title: 'Envio de foto',
    defaultMessage:
      "<p style='margin-bottom: 8px'>Você pode enviar apenas um arquivo JPG, JPEG, PNG ou PDF O tamanho máximo do arquivo é de 10 Mb</p><p style='margin-bottom: 8px'>O tamanho máximo do arquivo é de 10 Mb</p>",
    image: IconInvestigation,
    buttonText: 'Escolher Foto',
    anchor: 'bottom',
    buttonSecondaryText: 'Voltar',
    photoType: 'BACK',
    isEnablePhoto: true,
    pageTitle: `${BPV_PAGE_TITLE_PREFIX}/signup/id-validation-back`,
    buttonEventName: 'id_validation_back_choose_file_click',
  },
  PROOF_OF_RESIDENCE: {
    title: 'Envio de foto',
    defaultMessage:
      "<p style='margin-bottom: 8px'>Você pode enviar apenas um arquivo JPG, JPEG, PNG ou PDF O tamanho máximo do arquivo é de 10 Mb</p><p style='margin-bottom: 8px'>O tamanho máximo do arquivo é de 10 Mb</p>",
    image: IconInvestigation,
    buttonText: 'Escolher Foto',
    anchor: 'bottom',
    buttonSecondaryText: 'Voltar',
    photoType: 'PROOF_OF_RESIDENCE',
    isEnablePhoto: true,
    pageTitle: `${BPV_PAGE_TITLE_PREFIX}/signup/address-validation`,
    buttonEventName: 'address_validation_choose_file_click',
  },
  ADD_CARD_ERROR: {
    title: 'Erro ao adicionar o seu cartão',
    defaultMessage:
      'Verifique os dados ou utilize outro cartão. Caso tenha dúvidas, entre em contato com seu banco',
    defaultRoute: ROUTES.BPV_HOME,
    image: IconError2,
    buttonText: 'Fechar',
    anchor: 'bottom',
  },
  CHECK_IN: {
    anchor: 'bottom',
    defaultMessage:
      'Você precisa estar no local de liberação da bike  para fazer check-in. O QR code é válido apenas por alguns segundos.<br/> <br/>Importante, leve um documento pessoal com foto!',
    image: IconLoadingLocation,
    title: 'Check-in',
    buttonText: 'Fazer check-in',
    defaultRoute: ROUTES.CHECKIN_WITHDRAW,
    buttonSecondaryText: 'Voltar',
    secondaryRoute: ROUTES.SCHEDULE_DETAIL,
    events: ['checkin_details_modal_view'],
    pageTitle: `${BPV_PAGE_TITLE_PREFIX}/schedule-pickup/details`,
    buttonEventName: 'checkin_details_primary_btn_click',
    buttonSecondaryEventName: 'checkin_details_secondary_btn_click',
  },
  CHECK_IN_SUCCESS: {
    anchor: 'bottom',
    defaultMessage:
      'Depois de comprar o plano no ponto de retirada, volte para tela inicial e gere o qr code para liberar a bike.',
    image: IconLocation,
    title: 'Check-in feito',
    buttonText: 'Voltar pra tela inicial',
    events: ['checkin_success'],
    pageTitle: `${BPV_PAGE_TITLE_PREFIX}/checkin/qrcode`,
    // No momento será redirecionado para o IfoodPedal
    // TODO: Fase3 devemos descomentar esse código.
    // defaultRoute: ROUTES.UNLOCK_BIKE
  },
  UNLOCK_BIKE_SUCESS: {
    anchor: 'bottom',
    defaultMessage: unlockBikeSuccess,
    image: IconWallet,
    title:
      "<span style='display: flex; text-align: center'>Bike escolhida? <br >Agora é só pagar!</span>",
    buttonText: 'Ir para pagamento',
    defaultRoute: ROUTES.BPV_HOME,
  },

  ADD_CARD_FAIL: {
    anchor: 'bottom',
    defaultMessage:
      'Verifique os dados ou utilize outro cartão. Caso tenha dúvidas, entre em contato com seu banco',
    image: IconWallet,
    title: 'Erro ao adicionar o seu cartão',
    buttonText: 'Entendi',
  },
};
