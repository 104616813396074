import React from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import * as S from './styles';
import { useToast } from '../../../hooks/useToast';
import { Props } from './types';

const TIME_MILISECONDS = 5000;
const Toast = ({
  variant,
  title,
  anchor = { vertical: 'top', horizontal: 'right' },
  bottom = '8px',
}: Props): JSX.Element => {
  const { showToast, setShowToast } = useToast();
  return (
    <S.CustomSnack
      open={showToast}
      anchorOrigin={anchor}
      autoHideDuration={TIME_MILISECONDS}
      onClose={setShowToast}
      bottom={bottom}
      backgroundcolor={variant}
    >
      <S.CustomAlert
        onClose={setShowToast}
        severity={variant}
        backgroundcolor={variant}
        sx={{ width: '100%' }}
        icon={
          variant === 'error' ? (
            <CancelIcon sx={{ color: '#fff' }} />
          ) : (
            <CheckCircleIcon sx={{ color: '#fff' }} />
          )
        }
      >
        {title}
      </S.CustomAlert>
    </S.CustomSnack>
  );
};

export default Toast;
