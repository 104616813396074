import { Alert, Snackbar, styled } from '@mui/material';

interface Props {
  bottom?: string;
  backgroundcolor: 'success' | 'error';
}

export const CustomSnack = styled(Snackbar)<Props>(({ bottom }) => ({
  bottom,
}));

export const CustomAlert = styled(Alert)<Props>(({ theme, backgroundcolor }) => ({
  background: backgroundcolor === 'error' ? theme.palette.error.main : theme.palette.success.light,
  color: theme.palette.common.white,
}));
